@import '../../assets/styles/variables';
.info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 16px;

  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8vh;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
  .wrap {
    max-width: 554px;
    margin: auto;

    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      max-width: 588px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      max-width: 956px;
    }
  }
  .container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: #010101;
    }

    &::-webkit-scrollbar {
      width: 8px;

      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        width: 13px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        width: 13px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--game-color);
    }
  }
}

.autoplay {
  color: var(--game-color);
  font-size: 32px;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 44px;
    margin: 64px 0;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 44px;
    margin: 64px 0;
  }
}

.title {
  color: var(--game-color);
  font-size: 32px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 44px;
    margin: 64px 0;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 44px;
    margin: 64px 0;
  }
}

.title1 {
  color: var(--game-color);
  font-size: 32px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 32px;
    margin: 64px 0;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 32px;
    margin: 64px 0;
  }
}

.arrow_title {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 20px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 20px;
  }
}

.subtitle {
  color: var(--game-color);
  font-size: 24px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 32px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    margin: 64px 0;
  }
}

.row {
  display: flex;
  margin: 16px 0;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  .col {
    width: 100%;
    padding: 0 16px;
  }
}

.p {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-align: left;
  margin: 0 0 10px;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 26px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 26px;
  }

  &.center {
    text-align: center;
  }

  span {
    color: var(--game-color);
  }
}

.image {
  display: flex;
  flex-direction: column;

  img,
  svg {
    display: block;
    width: 56px;
    height: 56px;
    margin: 0 auto 8px;
  }

  &__text {
    font-size: 16px;
    color: #fff;
    text-align: center;
  }
}

.paytable-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  $five-of-kind-color: #f2a2da; // hsl(0 100% 75%);

  &__item {
    display: flex;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    align-items: center;
    word-break: break-word;
    width: 50%;
    padding: 0 8px 16px;

    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      padding: 0 16px 32px;
    }
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      padding: 0 16px 32px;
    }

    img {
      display: block;
      grid-row: 1;
      grid-column: 1;
      width: 100%;
      height: auto;
    }

    .content {
      grid-row: 1;
      grid-column: 2;
      white-space: nowrap;
      padding-left: 12px;
      align-items: flex-start;
      justify-content: center;
      font-size: 0.65rem;
      color: var(--game-color);
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 20px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 20px;
      }

      .multiplier {
        color: #fff;
      }
    }

    p.caption {
      color: #fff;
      font-size: 0.65rem;
      font-weight: 700;
      text-align: center;
      padding: 0;
      margin: 0;

      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 16px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 16px;
      }
    }
  }
  &__explain {
    @extend .p;
    color: $five-of-kind-color;
    width: 100%;
    text-align: center;
  }

  &.wild {
    display: flex;
    word-break: break-word;
    // white-space: break-spaces;
    .paytable-list__item {
      flex-direction: column;
      // width: 50%;
      padding-bottom: 32px;
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        padding-bottom: 64px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        padding-bottom: 64px;
      }

      .img {
        width: 50%;
        margin-bottom: 8px;

        .image__title {
          color: #fff;
          text-align: center;
          font-size: 1rem;
          margin-bottom: 8px;
          @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
            font-size: 32px;
          }
          @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
            font-size: 32px;
          }
        }
      }
      .content {
        width: auto;
        padding: 0;
        font-size: 16px;
        @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
          font-size: 26px;
        }
        @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
          font-size: 26px;
        }
      }
    }
  }
}

.payLines {
  display: block;
  width: 100%;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
}

.feature {
  .image {
    img {
      display: block;
      width: 100px;
      height: auto;
      margin: auto;
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        width: 200px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        width: 200px;
      }
    }
  }
}

.buttonBehaviors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  &__item {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #fff;
    padding: 0 8px 16px;
    text-align: center;

    @media screen and (orientation: portrait) {
      width: 50%;
    }

    svg {
      display: block;
      width: 56px;
      height: 56px;
      margin-bottom: 8px;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
    }
    &.start {
      width: 100%;
      svg {
        width: 210px;
        // height: 128px;
      }
    }
    &.spin {
      margin-left: 25%;
      svg {
        width: 128px;
        height: 128px;
      }
    }
    &.stop {
      margin-right: 25%;
      svg {
        width: 128px;
        height: 128px;
      }
    }

    @media screen and (orientation: portrait) {
      &.start,
      &.spin,
      &.stop {
        margin: 0 !important;
      }
    }
  }
}
